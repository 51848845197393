<template>
    <v-container grid-list-md pa-0 relative class="page-layout" style="border-top:10px solid #e3e3e3;">
        <v-layout row wrap>
            <v-flex xs12 sm12>
                <v-card>
                    <v-card-title class="title">
                        <v-icon>layers</v-icon>
                        School Payment
                        <v-spacer></v-spacer>
                        <add-button permission="grade-create" @action="form.dialog = true">New Payment</add-button>
                    </v-card-title>
                    <v-card-title class="title">
                        <v-flex xs3 sm4>
                            <v-select :loading="schoolLoading"
                                      :items="schools" class="pa-0" label="School"
                                      v-model="school"/>
                        </v-flex>
                    </v-card-title>
                    <v-data-table :headers="headers" :items="form.items.data"
                                  :pagination.sync="pagination"
                                  :rows-per-page-items="rowsPerPageItems"
                                  :total-items="form.items.meta.total"
                                  :loading="form.loading">
                        <template slot="items" slot-scope="props">
                            <td>{{ props.index + form.items.meta.from }}</td>
                            <td>{{findSchool(props.item.company_id)}}</td>
                            <td>{{props.item.bill_date ? props.item.bill_date : props.item.payment_date}}</td>
                            <td>{{props.item.bill_amount ? props.item.bill_amount : '-'}}</td>
                            <td>{{props.item.paid_amount ? props.item.paid_amount : '-'}}</td>
                            <td>
                                <span v-if="props.item.paid_amount!=='0.00' &&  props.item.is_verified===null"> <v-chip color="warning" text-color="white" small>PENDING</v-chip> </span>
                                <span v-else-if="props.item.paid_amount &&  props.item.is_verified===0"> <v-chip color="error" text-color="white" small>UNVERIFIED</v-chip></span>
                                <span v-else-if="props.item.paid_amount &&  props.item.is_verified===1"> <v-chip color="success" text-color="white" small>SUCCESS</v-chip></span>
                            </td>
                            <td class="text-xs-center">
                                <a style="text-decoration: none" v-if="props.item.documents && props.item.documents.invoice && props.item.documents.invoice.length"
                                   :href="props.item.documents.invoice" target="_blank">
                                    <v-btn small icon color="primary" class="pa-0 ma-1">
                                        <v-icon color="white" small>cloud_download</v-icon>
                                    </v-btn>
                                </a>
                                <a style="text-decoration: none" v-if="props.item.documents && props.item.documents.voucher &&props.item.documents.voucher.length"
                                   :href="props.item.documents.voucher" target="_blank">
                                    <v-btn small icon color="warning" class="pa-0 ma-1">
                                        <v-icon color="white" small>cloud_download</v-icon>
                                    </v-btn>
                                </a>
                                <a style="text-decoration: none" v-if="props.item.documents && props.item.documents.receipt && props.item.documents.receipt.length"
                                   :href="props.item.documents.receipt" target="_blank">
                                    <v-btn small icon color="error" class="pa-0 ma-1">
                                        <v-icon color="white" small>print</v-icon>
                                    </v-btn>
                                </a>
                            </td>
                            <td class="text-xs-right">
                                <span v-if="!props.item.is_verified && props.item.bill_amount !== '0.00'">
                                    <edit-button permission="subject-read"
                                                 @agree="form.edit(props.item),editData(props.item)"/>
                                    <delete-button permission="subject-read" @agree="form.delete(props.item.id)"/>
                                </span>
                                <span v-if="props.item.paid_amount !== '0.00' &&  props.item.is_verified===null">
                                     <v-btn @click="form.edit(props.item,false), verifyDialog = true"
                                            small
                                            icon color="primary"
                                            class="pa-0 ma-1">
                                        <v-icon color="white" small>crop_free</v-icon>
                                    </v-btn>
                                </span>
                            </td>

                        </template>
                    </v-data-table>
                </v-card>
            </v-flex>
            <v-dialog v-model="verifyDialog" persistent max-width="350">
                <v-card>
                    <v-card-title class="title pa-3 primary white--text">
                        <v-icon class="mr-2 white--text">warning</v-icon>
                        Please, wait!
                    </v-card-title>
                    <v-card-text class="ma-0">{{'Are you sure you want to verify this item?'}}</v-card-text>
                    <v-card-text>
                        <v-layout row wrap>
                            <v-flex xs12 text-xs-center class="ma-0 pa-0">
                                <v-scroll-y-transition>
                                    <v-btn round outline @click="onPickFile" v-if="!imagePicker.url">
                                        <v-icon left dark small>add_a_photo</v-icon>
                                        Upload Receipt
                                    </v-btn>
                                </v-scroll-y-transition>
                                <input type="file" style="display: none" ref="image" accept="image/*"
                                       @change="onFilePicked">
                            </v-flex>
                            <v-scroll-y-reverse-transition>
                                <v-flex xs12 text-xs-center class="ma-0 pa-0" v-if="imagePicker.url"
                                        style="position: relative;">
                                    <v-btn flat outline icon color="green" @click="onPickFile">
                                        <v-icon small>compare_arrows</v-icon>
                                    </v-btn>
                                    <v-avatar :size="112" color="grey lighten-4">
                                        <img :src="imagePicker.url" alt="avatar">
                                    </v-avatar>
                                    <v-btn flat outline icon color="red" @click="resetImagePicker">
                                        <v-icon small>close</v-icon>
                                    </v-btn>
                                </v-flex>
                            </v-scroll-y-reverse-transition>
                            <v-flex xs12>
                                <v-textarea placeholder="Remarks" v-model="form.remarks" :rows="4"></v-textarea>
                            </v-flex>
                        </v-layout>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="success" outline small @click="verifyDialog=false">Close</v-btn>
                        <v-btn color="error" outline small @click="unVerify">Unverify</v-btn>
                        <v-btn color="primary" outline small @click="verify">verify</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <v-dialog v-model="form.dialog" persistent max-width="500px">
                <v-card>
                    <v-card-title class="primary white--text">
                        <span class="title">Add/Update</span>
                    </v-card-title>
                    <v-card-text class="pb-1">
                        <v-form ref="form" @submit.prevent="save"
                                @keydown.native="form.errors.clear($event.target.name)" @keyup.enter="save"
                                lazy-validation>
                            <v-layout row wrap pt-2>
                                <v-flex xs6 sm6>
                                    <v-select v-model="form.company_id" :items="schools"
                                              label="Select School"
                                              name="company_id" :height="25" :readonly="form.loading"
                                    />
                                </v-flex>
                                <v-flex xs6 sm6>
                                    <v-text-field autocomplete="off" :mask="'####-##-##'" label="Bill Date*"
                                                  required
                                                  height="25"
                                                  v-model="form.bill_date" name="bill_date"
                                                  :rules="dateValidation"/>
                                </v-flex>
                                <v-flex xs6 sm6>
                                    <v-text-field autocomplete="off" label="Amount"
                                                  required type="number"
                                                  height="25"
                                                  v-model="form.bill_amount" name="amount"/>
                                </v-flex>
                                <v-flex xs6 text-xs-center class="ma-0 pa-0">
                                    <v-scroll-y-transition>
                                        <v-btn round outline @click="onPickFile" v-if="!imagePicker.url">
                                            <v-icon left dark small>add_a_photo</v-icon>
                                            Select Document
                                        </v-btn>
                                    </v-scroll-y-transition>
                                    <input type="file" style="display: none" ref="image" accept="image/*"
                                           @change="onFilePicked">
                                </v-flex>
                                <v-scroll-y-reverse-transition>
                                    <v-flex xs12 text-xs-center class="ma-0 pa-0" v-if="imagePicker.url"
                                            style="position: relative;">
                                        <v-btn flat outline icon color="green" @click="onPickFile">
                                            <v-icon small>compare_arrows</v-icon>
                                        </v-btn>
                                        <v-avatar :size="112" color="grey lighten-4">
                                            <img :src="imagePicker.url" alt="avatar">
                                        </v-avatar>
                                        <v-btn flat outline icon color="red" @click="resetImagePicker">
                                            <v-icon small>close</v-icon>
                                        </v-btn>
                                    </v-flex>
                                </v-scroll-y-reverse-transition>
                            </v-layout>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="warning" outline @click="form.dialog = false, form.reset()">Close</v-btn>
                        <v-btn color="success" outline @click="save">Save</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-layout>
    </v-container>
</template>
<script>
    import {mapState} from 'vuex';
    import ImageCompressor from 'image-compressor.js'


    const NepaliDate = require('nepali-date');
    const nd = new NepaliDate();

    import Form from '@/library/Form'
    import {validateDate} from "@/library/helpers";

    export default {
        data: (vm) => ({
            form: new Form({
                company_id: '',
                bill_date: '',
                bill_amount: '',
                document_invoice: '',
                is_verified: '',
                remarks: '',
                document_receipt: '',
            }, '/api/account/school-payment'),
            search: null,
            rowsPerPageItems: [5, 10, 25, 50, 75],
            pagination: {
                rowsPerPage: 25,
                sortBy: 'id'
            },
            headers: [
                {text: '#', align: 'center', value: 'id', width: 50, sortable: false},
                {text: 'School', align: 'left', value: 'school_id'},
                {text: 'Date', align: 'left', value: 'bill_date', width: 200},
                {text: 'Bill Amount', align: 'left', value: 'bill_amount'},
                {text: 'Paid Amount', align: 'left', value: 'paid_amount'},
                {text: 'Status', align: 'left', value: 'status'},
                {text: 'Document', align: 'left', value: 'document'},
                {text: 'Action', align: 'right', sortable: false, width: 200}
            ],
            verifyDialog: false,
            schools: [],
            school: '',
            schoolLoading: false,
            dateValidation: [
                (v) => validateDate(v) || 'Invalid Date Format',
            ],
            imagePicker: {
                name: null,
                image: null,
                url: null
            },
            remarks: '',
            selectedSchool: '',
            id: '',
        }),

        computed: {
            ...mapState(['batch']),
        },
        mounted() {
            this.get();
            this.getSchool()
        },

        watch: {
            'pagination': function () {
                this.get();
            },
            'batch': function (value) {
                this.get();
            },
            school: function (v) {
                if (this.v) this.get()
            }
        },

        methods: {
            queryString() {
                let json = this.pagination;
                return '?' +
                    Object.keys(json).map(function (key) {
                        if (![null, undefined].includes(json[key]))
                            return encodeURIComponent(key) + '=' + encodeURIComponent(json[key]);
                    }).join('&');
            },
            get(params) {
                let query = [null, undefined].includes(params) ? this.queryString() : params;
                this.form.get(null, query).then(({data}) => {
                    this.pagination.totalItems = data.meta.total
                })
            },

            save() {
                this.form.document_invoice = this.imagePicker.image;
                this.form.bill_date = this.form.bill_date.dateForm();
                this.form.store().then(data => {
                    // this.$events.fire('notification', {message: data.data.message, status: 'success'});
                }).catch(e => {
                    this.$events.fire('notification', {message: e.response.data.message, status: 'error'})
                });
            },
            getSchool() {
                this.$rest.get('/api/company?slim&type=school&rowsPerPage=25').then(res => {
                    this.schools = res.data.data.map(r => {
                        return {value: r.id, text: r.name}
                    });
                });

            },
            findSchool(id) {
                if (!id || this.schools.length < 1) return '';
                let output = this.schools.find(school => school.value === id);

                if (Object.keys(output).length) {
                    return output.text;
                }
                return '-';
            },
            onFilePicked(e) {
                const files = e.target.files;
                let fileName = files[0].name;
                if (fileName.lastIndexOf('.') <= 0) {
                    this.$store.dispatch('notifyError', "Please upload a valid image file!");
                    return
                }
                const fileReader = new FileReader();
                fileReader.readAsDataURL(files[0]);
                fileReader.addEventListener('load', () => {
                    this.imagePicker.url = fileReader.result;
                    this.imagePicker.name = fileName;

                    const imageCompressor = new ImageCompressor();
                    imageCompressor.compress(files[0], {quality: .7})
                        .then((result) => {
                            this.imagePicker.image = result
                        })
                        .catch((err) => {
                            // Handle the error
                        })
                })
            },
            resetImagePicker() {
                this.imagePicker = {
                    url: undefined,
                    image: undefined,
                    name: undefined
                };
                this.$refs.image.value = "";
            },
            onPickFile() {
                this.$refs.image.click()
            },

            editData(item) {
                // this.form.company_id = item.school_id;
                // this.imagePicker.image = item.documents ? item.documents.invoice : '';

                // console.log(item)
            },
            verify() {
                this.form.is_verified = 1;
                this.form.document_receipt = this.imagePicker.image;
                this.form.endpoint = this.form.endpoint.replace('/pay/verify', '') + '/pay/verify';
                this.form.fireFetch = false;

                this.form.store().then(res => {
                    this.verifyDialog = false;
                    this.form.endpoint = this.form.endpoint.replace('/pay/verify', '');
                    this.get()
                });
            },
            unVerify() {
                this.form.is_verified = 0;
                this.form.document_receipt = this.imagePicker.image;
                this.form.endpoint = this.form.endpoint.replace('/pay/verify', '') + '/pay/verify';
                this.form.fireFetch = false;

                this.form.store().then(res => {
                    this.verifyDialog = false;
                    this.form.endpoint = this.form.endpoint.replace('/pay/verify', '');
                    this.get()
                });
            }


        }
    }
</script>
<style lang="scss">

    /*.page-layout {*/

    /*}*/


</style>